import moment from "moment";
import { EM_JORNADA } from "./constants";
class Rules {
  static create(userReference, employeeReference, role) {
    return {
      user: userReference,
      employee: employeeReference,
      role: role,
      permissions: Rules.getPermissionsFromRole(role),
    };
  }

  static getPermissionsFromRole(role) {
    return ["all"];
  }
}
export default Rules;
