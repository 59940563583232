import ProgressCircle from "../components/ProgressCircle/ProgressCircle";
import Loadable from "react-loadable";

const PagesLoadable = Loadable({
  loader: () => import("layouts/Pages.jsx"),
  loading: ProgressCircle
});
const DashboardLoadable = Loadable({
  loader: () => import("layouts/Dashboard.jsx"),
  loading: ProgressCircle
});
const CPFModeLoadable = Loadable({
  loader: () => import("container/CPFModeContainer/CPFModeContainer.js"),
  loading: ProgressCircle
});

const indexRoutes = [
  { path: "/inicio", name: "Pages", component: PagesLoadable },
  {
    path: "/dashboard",
    name: "Home",
    component: DashboardLoadable,
    auth: true
  }
];

export default indexRoutes;
