import db, {firebase} from "./firebase";
import {ON_WORKING} from "../model/constants";

class TeamsService {
  static get collectionName() {
    return "teams";
  }

  static teamsQueries(employee, companyReference, status = false) {
    if (!employee.managerOfTeams || employee.managerOfTeams.length === 0) {
      throw new TypeError(`${employee.managerOfTeams} is not a valid value`);
    }

    let membersDocsQueriesSplited = []
    let managersDocsQueriesSplited = []

    let membersQuery = null

    let managersQuery = null

    if (employee.managerOfTeams.length > 10) {
      const chunk = []

      employee.managerOfTeams.forEach((team, index) => {
        if (index % 10 === 0) {
          chunk.push([])
        }

        chunk[chunk.length - 1].push(team)
      })

      membersDocsQueriesSplited = chunk.map((chunk) => {
        return db
          .collection("employees")
          .where("company", "==", companyReference)
          .where("memberOfTeams", "array-contains-any", chunk)
      })

      managersDocsQueriesSplited = chunk.map((chunk) => {
        return db
          .collection("employees")
          .where("company", "==", companyReference)
          .where("managerOfTeams", "array-contains-any", chunk)
      })
    } else {
      membersQuery = db
        .collection("employees")
        .where("company", "==", companyReference)
        .where("memberOfTeams", "array-contains-any", employee.managerOfTeams)
        .where("status", "==", status ? status : ON_WORKING);

      managersQuery = db
        .collection("employees")
        .where("company", "==", companyReference)
        .where("managerOfTeams", "array-contains-any", employee.managerOfTeams)
        .where("status", "==", status ? status : ON_WORKING);
    }

    return {membersQuery, managersQuery, membersDocsQueriesSplited, managersDocsQueriesSplited};
  }

  static async getMembersOfTeams(employee, companyReference, status, parser) {
    try {
      const {membersQuery, membersDocsQueriesSplited} = this.teamsQueries(
        employee,
        companyReference,
        status
      );

      const memberDocs = membersDocsQueriesSplited.length <= 1 ? await membersQuery.get() : {
        docs: await (async () => {
          let docsA = []

          for await (const query of membersDocsQueriesSplited) {
            const docs = await query.get()

            docsA = [...docsA, ...docs.docs]
          }

          return docsA
        })()
      };

      return memberDocs.docs.map(member => {
        return parser
          ? parser({
            ...member.data(),
            ref: member.ref,
            id: member.ref.id,
            selfReference: member.ref.path
          })
          : {
            ...member.data(),
            ref: member.ref,
            id: member.ref.id,
            selfReference: member.ref.path
          };
      });
    } catch (error) {
      return [];
    }
  }

  static async getManagersOfTeams(employee, companyReference, status, parser) {
    if (!employee.managerOfTeams || employee.managerOfTeams.length === 0) {
      throw new TypeError(`${employee.managerOfTeams} is not a valid value`);
    }

    const {managersQuery, managersDocsQueriesSplited} = this.teamsQueries(
      employee,
      companyReference,
      status
    );

    const managerDocs = managersDocsQueriesSplited.length <= 1 ? await managersQuery.get() : {
      docs: await (async () => {
        let docsA = []

        for await (const query of managersDocsQueriesSplited) {
          const docs = await query.get()

          docsA = [...docsA, ...docs.docs]
        }

        return docsA
      })()
    };

    return managerDocs.docs.map(manager => {
      return parser
        ? parser({
          ...manager.data(),
          ref: manager.ref,
          id: manager.ref.id,
          selfReference: manager.ref.path
        })
        : {
          ...manager.data(),
          ref: manager.ref,
          id: manager.ref.id,
          selfReference: manager.ref.path
        };
    });
  }

  static async getMembersAndManagers(
    employee,
    companyReference,
    status,
    parser
  ) {
    try {
      const managerPromises = this.getMembersOfTeams(
        employee,
        companyReference,
        status,
        parser
      );

      const memberPromises = this.getManagersOfTeams(
        employee,
        companyReference,
        status,
        parser
      );

      const employees = await Promise.all([managerPromises, memberPromises]);

      return employees.flatMap(employee => employee);
    } catch (error) {
      return [];
    }
  }

  static async findManagersByMember(member, companyReference, parser) {
    try {
      const managers = await db
        .collection("employees")
        .where("company", "==", companyReference)
        .where("managerOfTeams", "array-contains-any", member)
        .get();

      return managers.docs.map(manager => {
        return parser
          ? parser({
            ...manager.data(),
            ref: manager.ref,
            id: manager.ref.id,
            selfReference: manager.ref.path
          })
          : {
            ...manager.data(),
            ref: manager.ref,
            id: manager.ref.id,
            selfReference: manager.ref.path
          };
      });
    } catch (error) {
      return [];
    }
  }
}

export default TeamsService;
