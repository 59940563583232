import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import userReducer from "./reducers/userReducer";
import messagingReducer from "./reducers/messagingReducer";
import CPFModeReducer from "./reducers/CPFModeReducer";
import { updatePermissions } from "./actions";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];

const reducers = combineReducers({
  userReducer: userReducer,
  messagingReducer: messagingReducer,
  CPFModeReducer: CPFModeReducer,
  form: formReducer
});

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, updatePermissions))
);

export default store;
