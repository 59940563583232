import moment from "moment";

import { firebase } from "../services/firebase";
import {
  TRIALING,
  TRIAL_MAX_EMPLOYEES,
  UNPAID,
  ENDED,
  CANCELED,
  PAID,
  PENDING_PAYMENT
} from "./constants";
import { getServerTimestamp } from "../services/generalService";

class Subscription {
  static DEFAULT = {
    subscriptionId: null,
    paymentStatus: TRIALING,
    subscriptionSource: null,
    startDate: null,
    endDate: null,
    company: null,
    plan: {
      id: "0",
      name: "Avaliação",
      isCustom: true,
      maxEmployees: TRIAL_MAX_EMPLOYEES,
      value: null
    },
    oldPlan: {
      id: "0",
      name: "Avaliação",
      isCustom: true,
      maxEmployees: TRIAL_MAX_EMPLOYEES,
      value: null
    },
    gracePeriod: 0,
    logs: []
  };

  static LEGACY = {
    ...this.DEFAULT,
    plan: {
      id: "0",
      name: "FREE",
      isCustom: true,
      maxEmployees: 10,
      value: null
    },
    oldPlan: {
      id: "0",
      name: "FREE",
      isCustom: true,
      maxEmployees: 10,
      value: null
    }
  };

  static STATUS_NAMES = {
    [UNPAID]: "NÃO PAGO",
    [PAID]: "PAGO",
    [ENDED]: "FINALIZADO",
    [CANCELED]: "CANCELADO",
    [PENDING_PAYMENT]: "PAGAMENTO PENDENTE",
    [TRIALING]: "PERÍODO DE TESTE"
  };

  static create({
    paymentStatus,
    subscriptionSource,
    startDate,
    endDate,
    subscriptionId,
    company,
    plan,
    oldPlan,
    gracePeriod,
    logs
  }) {
    const subscription = {
      paymentStatus,
      subscriptionSource,
      startDate,
      endDate,
      subscriptionId,
      company,
      plan,
      oldPlan,
      gracePeriod,
      logs,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    Object.keys(subscription).map(key =>
      subscription[key] === undefined
        ? (subscription[key] = this.DEFAULT[key])
        : subscription[key]
    );
    return subscription;
  }

  static hasInvalidStatus(subscription) {
    return (
      !!subscription &&
      (subscription.paymentStatus === UNPAID ||
        subscription.paymentStatus === ENDED)
    );
  }

  static hasEnded(subscription) {
    return (
      !!subscription &&
      (subscription.paymentStatus === UNPAID ||
        subscription.paymentStatus === ENDED ||
        subscription.paymentStatus === CANCELED)
    );
  }

  static async isExpired(subscription, date = false) {
    if (subscription) {
      let momentDate = null;
      if (!date) {
        try {
          const serverTimestamp = await getServerTimestamp(false);
          momentDate = moment(serverTimestamp);
        } catch (e) {
          return false;
        }
      } else {
        momentDate = moment(date);
      }
      const expireDate = moment(subscription.endDate.toDate());
      return momentDate.isAfter(expireDate);
    }
    return false;
  }
}

export default Subscription;
