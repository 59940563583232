import {firebase} from './firebase';

class AuthService {
  static logginUser(email, password){
    try {
      return firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (e) {
      console.info(e);
    }
  }

  static logoutUser(){
    return firebase.auth().signOut();
  }

}

export default AuthService;
