import React from 'react'
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';

const ProgressCircle = ({size, color}) => {
  return (
    <GridContainer justify="center">
      <GridItem style={{textAlign: 'center'}}>
        <CircularProgress style={{
          height: size, 
          width: size,
          color: color === 'purple' ? '#52197f' : color,
        }}/>
      </GridItem>
    </GridContainer>
  )
}

export default ProgressCircle
