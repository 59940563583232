import React, { Component, Fragment } from "react";

import NewReleaseIcon from "@material-ui/icons/NewReleasesRounded";

import Snackbar from "../Snackbar/Snackbar";
import Button from "../CustomButtons/Button";

class NewContent extends Component {
  state = {
    newContent: false
  };

  componentDidMount() {
    window.addEventListener("newContentAvailable", event => {
      this.skipWaiting = event.detail.skipWaiting;
      this.setState({
        newContent: true
      });
    });
  }

  handleReload = () => {
    this.setState({ newContent: false }, () => {
      this.skipWaiting && this.skipWaiting();
      setTimeout(() => window.location.reload(), 150);
    });
  };

  render() {
    return (
      <Snackbar
        open={this.state.newContent}
        icon={NewReleaseIcon}
        message={
          <Fragment>
            <p>Uma nova versão está disponível</p>
            <p>Atualize para obter a versão mais recente</p>
            <Button
              size="sm"
              color="white"
              round
              fullWidth
              onClick={this.handleReload}
            >
              Atualizar agora
            </Button>
          </Fragment>
        }
        place="tr"
        color="rose"
        close
        closeNotification={() => this.setState({ newContent: false })}
      />
    );
  }
}

export default NewContent;
