import db, { firebase } from "./firebase";

export function updateDocument(path, data) {
  return db.doc(path).update({
    ...data,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp()
  });
}

export function deleteDocument(path) {
  return db.doc(path).delete();
}

export function addDocument(path = "/", collection, data) {
  return db
    .doc(path)
    .collection(collection)
    .add(data);
}

export function setDocument(path, data) {
  return db.doc(path).set({
    ...data,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp()
  });
}

export function getDocument(path) {
  return db.doc(path);
}

export function snapshotDocuments(query, callback, callbackError, parser) {
  return query.onSnapshot(
    async documents => {
      const documentsWithReference = documents.docs.map(document => {
        return parser
          ? parser({
              ...document.data(),
              id: document.ref.id,
              selfReference: document.ref.path
            })
          : {
              ...document.data(),
              id: document.ref.id,
              selfReference: document.ref.path
            };
      });
      const resolvedDocuments = await Promise.all(documentsWithReference);
      return callback ? callback(resolvedDocuments) : resolvedDocuments;
    },
    error => {
      return callbackError ? callbackError(error) : null;
    }
  );
}

export function queryDocuments(query, callback, callbackError, parser) {
  return query
    .get()
    .then(async documents => {
      const documentsWithReference = documents.docs.map(document => {
        return parser
          ? parser({
              ...document.data(),
              ref: document.ref,
              id: document.ref.id,
              selfReference: document.ref.path
            })
          : {
              ...document.data(),
              ref: document.ref,
              id: document.ref.id,
              selfReference: document.ref.path
            };
      });
      const resolvedDocuments = await Promise.all(documentsWithReference);
      return callback ? callback(resolvedDocuments) : resolvedDocuments;
    })
    .catch(error => {
      return callbackError ? callbackError(error) : null;
    });
}
