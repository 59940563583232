import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { primaryColor } from "../../../assets/jss/material-dashboard-pro-react";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  loading: {
    height: "100px !important",
    width: "100px !important",
    color: primaryColor
  }
};

const LoaderScreen = ({ classes }) => (
  <div className={classes.container}>
    <CircularProgress className={classes.loading} />
  </div>
);

export default withStyles(styles)(LoaderScreen);
