import React, { Component, Fragment } from "react";
import queryString from "query-string";
import { createBrowserHistory } from "history";
import { Router, Switch, Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import indexRoutes from "routes/index.jsx";
import ProtectedRoute from "./container/ProtectedRoute";
import { hot } from "react-hot-loader";
import LoaderScreen from "./views/Pages/LoaderScreen/LoaderScreen";
import { getURN } from "./utils/dynamicLinks";
import { checkAuthStatus, changeToNotificationEmployee } from "./store/actions";
import { getDocument } from "./services/FirestoreService";

const hist = createBrowserHistory();
class Root extends Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    const params = queryString.parse(hist.location.search);
    const basedOnCompany =
      params.from == "notification" ? params.company : undefined;
    this.props.checkAuthStatus(basedOnCompany);

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", event => {
        const { data } = event;
        const url = data.url;
        if (url) {
          const { userData, companyData } = this.props;
          
          const companyId =
            companyData && getDocument(companyData.selfReference).id;
          this.props
            .changeToNotificationEmployee(userData, companyId, data.companyId)
            .then(() => {
              hist.push({
                pathname: getURN(url),
                state: { type: data.type }
              });
            });
        }
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { userData, companyReference, companyData, subscription } = nextProps;
    if (
      userData &&
      this.state.loaded &&
      !companyReference &&
      !companyData &&
      !subscription
    ) {
      return true;
    }
    return !this.state.loaded;
  }

  static getDerivedStateFromProps(props, state) {
    const { userData, companyReference, companyData, subscription } = props;
    if (
      userData &&
      companyReference &&
      companyData &&
      subscription &&
      !state.loaded
    ) {
      return { loaded: true };
    } else if (
      userData &&
      state.loaded &&
      !companyReference &&
      !companyData &&
      !subscription
    ) {
      return { loaded: false };
    }
    return null;
  }

  render() {
    const userToken = JSON.parse(sessionStorage.getItem("currentUser"));
    return this.state.loaded || !userToken ? (
      <Fragment>
        <Router history={hist} key={Math.random()}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <ProtectedRoute
                  requireAuth={prop.auth}
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Router>
      </Fragment>
    ) : (
      <LoaderScreen />
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.userReducer.userData,
    companyReference: state.userReducer.companyReference,
    companyData: state.userReducer.companyData,
    subscription: state.userReducer.subscription
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkAuthStatus: companyId => dispatch(checkAuthStatus(companyId)),
    changeToNotificationEmployee: (
      userData,
      currentCompanyId,
      notificationCompanyId
    ) =>
      dispatch(
        changeToNotificationEmployee(
          userData,
          currentCompanyId,
          notificationCompanyId
        )
      )
  };
}

export default compose(
  hot(module),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Root);
