import moment from "moment";
import { UAParser } from "ua-parser-js";

export const getServerTimestamp = (localTimestampOnFail = true) => {
  if (process.env.NODE_ENV === "development") {
    return new Promise(resolve => {
      resolve(moment());
    });
  }

  const serverOne = fetch("https://use.ntpjs.org/v1/time.json", {
    method: "GET"
  }).then(res => res.json());
  const serverTwo = fetch("https://mobinvest.mobills.com.br/serverTime/", {
    method: "GET"
  }).then(res => res.json());
  // Servidores HTTP sendo bloqueado
  // const serverTwo = fetch("http://date.jsontest.com/", {
  //   method: "GET"
  // }).then(res => res.json());
  // const serverThree = fetch("http://time.jsontest.com/", {
  //   method: "GET"
  // }).then(res => res.json());
  const serverTimeout = new Promise(function(resolve, reject) {
    setTimeout(() => reject(new Error("request timeout")), 10000);
  });

  const promises = [serverOne, serverTwo].map(
    promise =>
      new Promise((resolve, reject) =>
        promise
          .then(value => resolve(value))
          .catch(error => setTimeout(() => reject(error), 10000))
      )
  );
  promises.push(serverTimeout);

  return Promise.race(promises)
    .then(timestamp =>
      timestamp.now
        ? moment.unix(timestamp.now)
        : moment(timestamp.milliseconds)
    )
    .catch(e => {
      if (localTimestampOnFail) {
        return moment();
      }
      throw e;
    });
};

export const getUserDeviceInfo = () => {
  return fetch("https://api.ipify.org?format=json")
    .then(response => response.json())
    .then(ip => {
      const parser = new UAParser();
      const result = parser.getResult();
      return {
        ip: ip.ip,
        device: `${result.browser.name} ${result.browser.version}`,
        operatingSystem: `${result.os.name} ${result.os.version}`
      };
    });
};
