import db from "./firebase";
import { getDocument } from "./FirestoreService";

class UserService {
  static get collectionName() {
    return "users";
  }
  static doc(id) {
    return db.collection(this.collectionName).doc(id);
  }
  static async findByEmail(email) {
    const user = await db
      .collection(this.collectionName)
      .where("email", "==", email)
      .get();
    return !user.empty ? user.docs[0] : null;
  }

  static async getEmployeeOfUserJobs(userReference, index) {
    const employeeReference = (await userReference.get()).data().jobs[index];
    return employeeReference;
  }

  static async getJobIndexOfUserFromCompany(userData, companyId) {
    const { jobs } = userData;
    for (const [index, job] of jobs.entries()) {
      const employeeData = (await job.get()).data();
      if (employeeData.company.id === companyId) {
        return index;
      }
    }

    return null;
  }
}

export default UserService;
