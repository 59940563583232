import urlBuilder from "build-url";

export function makeParametrizedLink(url, params) {
  const urlCustom = urlBuilder(url || `http://pontogo.app/inicio/cadastro/`, {
    queryParams: {
      ...params
    }
  });
  return urlCustom;
}
export function makeDynamicLongLink(employeeId, email, company) {
  const url = makeParametrizedLink(url, { employeeId, email, company });

  const options = {
    method: "POST",
    uri: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBoHLmEyI7IY5GRoXP6k6n-MgLlZZAaFm8`,
    body: {
      dynamicLinkInfo: {
        dynamicLinkDomain: "mobponto.page.link",
        link: url,
        androidInfo: {
          androidPackageName: "com.mobapps.mobponto",
          androidMinPackageVersionCode: "22"
        },
        iosInfo: {
          iosBundleId: "mobbusiness.pontogo",
          iosAppStoreId: "1391619920"
        }
      },
      suffix: {
        option: "UNGUESSABLE"
      }
    },
    json: true
  };

  return options;
}

export const getURN = url =>
  url ? url.slice(url.indexOf("/", url.indexOf("/") + 2)) : "/";
