import Rules from "../model/Rules";
import { firebase } from "../services/firebase";
import { ON_WORKING, ADMIN } from "../model/constants";
import { updateDocument, getDocument } from "../services/FirestoreService";

const isUndefinedOrNull = value => value === undefined || value === null;

export const normalizeEmployee = async (
  employee,
  employeePath,
  userReference
) => {
  try {
    let normalizedEmployee = null;

    // STATUS
    if (isUndefinedOrNull(employee.status)) {
      normalizedEmployee = { ...normalizedEmployee, status: ON_WORKING };
    }

    // DOCUMENTATION
    if (isUndefinedOrNull(employee.documentation)) {
      normalizedEmployee = {
        ...normalizedEmployee,
        documentation: { cpf: null, pis: null }
      };
    }

    // CONTACT
    if (isUndefinedOrNull(employee.contact)) {
      normalizedEmployee = {
        ...normalizedEmployee,
        contact: { phone: null, email: null }
      };
    }

    // RULE
    if (isUndefinedOrNull(employee.rule)) {
      const ruleReference = getDocument(
        `${employee.company.path}/rules/${userReference.id}`
      );
      const employeeReference = getDocument(employeePath);
      const ruleObject = Rules.create(userReference, employeeReference, ADMIN);
      await ruleReference.set(ruleObject);
      normalizedEmployee = { ...normalizedEmployee, rule: ruleReference };
    }

    if (normalizedEmployee) {
      await updateDocument(employeePath, normalizedEmployee);

      return { ...employee, ...normalizedEmployee };
    }

    return employee;
  } catch (error) {
    return employee;
  }
};

export const normalizeUser = async user => {
  try {
    let normalizedUser = null;

    // CONFIGURATION && CONFIGURATION.CURRENTEMPLOYEE
    if (isUndefinedOrNull(user.configuration)) {
      normalizedUser = {
        ...normalizedUser,
        configuration: {
          currentEmployee: 0
        }
      };
    } else if (isUndefinedOrNull(user.configuration.currentEmployee)) {
      normalizedUser = {
        ...normalizedUser,
        configuration: {
          ...user.configuration,
          currentEmployee: 0
        }
      };
    }

    if (normalizedUser) {
      const userId = getDocument(user.selfReference).id;
      await updateDocument(`users/${userId}`, normalizedUser);

      return { ...user, ...normalizedUser };
    }

    return user;
  } catch (error) {
    return user;
  }
};

export const normalizeCompany = async company => {
  try {
    let normalizedCompany = null;

    // HOLIDAYS - Comentado por conta da possível sobrescrita no array
    // if (isUndefinedOrNull(company.holidays)) {
    //   normalizedCompany = { ...normalizedCompany, holidays: [] };
    // }

    if (isUndefinedOrNull(company.address)) {
      normalizedCompany = {
        ...normalizedCompany,
        address: {
          address1: null,
          address2: null,
          neighborhood: null,
          city: null,
          state: null,
          country: null,
          number: null,
          postalCode: null
        }
      };
    }

    if (isUndefinedOrNull(company.contact)) {
      normalizedCompany = {
        ...normalizedCompany,
        contact: {
          phone: null,
          email: null
        }
      };
    }

    if (normalizedCompany) {
      await updateDocument(company.selfReference, normalizedCompany);

      return { ...company, ...normalizedCompany };
    }

    return company;
  } catch (error) {
    return company;
  }
};

export const parseFirestoreTypes = element => {
  if (element instanceof firebase.firestore.GeoPoint) {
    return element;
  }

  if (element instanceof firebase.firestore.Timestamp) {
    return element;
  }

  if (element instanceof firebase.firestore.DocumentReference) {
    return element.path;
  }

  if (element instanceof Array) {
    let parsedArray = [];
    for (const value of element) {
      const parsedValue = parseFirestoreTypes(value);
      parsedArray.push(parsedValue);
    }
    return parsedArray;
  }

  if (element instanceof Object) {
    let parsedObject = {};
    for (const key in element) {
      if (element.hasOwnProperty(key)) {
        parsedObject[key] = parseFirestoreTypes(element[key]);
      }
    }
    return parsedObject;
  }

  return element;
};
