import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: { main: "#353b81" },
    secondary: { main: "#e53935" }
  },
  typography: {
    body2: { color: "#3c4858", fontWeight: 300 }
  }
});
