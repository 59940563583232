import {
  ADMIN,
  EMPLOYEE,
  NO_COMPANY,
  ANNONYMOUS,
  MANAGER,
  REPORTS
} from "../model/constants";
import moment from "moment";

export function isAdminOrManager(role) {
  return role === ADMIN || role === MANAGER;
}

export function getNameOfRole(constant) {
  switch (constant) {
    case EMPLOYEE:
      return "employee";
    case ADMIN:
      return "admin";
    case NO_COMPANY:
      return "nocompany";
    case ANNONYMOUS:
      return "employee";
    case MANAGER:
      return "manager";
    case REPORTS:
      return "reports";
  }
}
export function sortDate(a, b) {
  if (a.length === b.length) {
    return moment(a, "DD/MM/YYYY").isAfter(moment(b, "DD/MM/YYYY")) ? 1 : -1;
  }
  return moment(a, "DD/MM/YYYY").isAfter(moment(b, "DD/MM/YYYY")) ? 1 : -1;
}
export function sortDateWithHours(a, b) {
  if (a.length === b.length) {
    return moment(a, "DD/MM/YYYY HH:mm:ss").isAfter(
      moment(b, "DD/MM/YYYY HH:mm:ss")
    )
      ? 1
      : -1;
  }
  return moment(a, "DD/MM/YYYY HH:mm:ss").isAfter(
    moment(b, "DD/MM/YYYY HH:mm:ss")
  )
    ? 1
    : -1;
}

export function sortAdmissionDate(a, b) {
  return moment(a.admissionDate).isAfter(moment(b.admissionDate)) ? 1 : -1;
}
