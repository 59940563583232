import {
  CPF_MODE_SAVE_COMPANIES_LIST,
  CPF_MODE_SAVE_COMPANY,
  CPF_MODE_LOGOUT_USER
} from "../actions/CPFMode";

const initialState = {
  companiesList: null,
  companyData: null
};

const CPFModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CPF_MODE_SAVE_COMPANIES_LIST:
      return {
        ...state,
        companiesList: action.payload
      };
    case CPF_MODE_SAVE_COMPANY:
      return {
        ...state,
        companyData: action.payload
      };
    case CPF_MODE_LOGOUT_USER:
      return {
        ...state,
        companiesList: null,
        companyData: null
      };
    default:
      return state;
  }
};

export default CPFModeReducer;
