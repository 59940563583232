import React, { Component } from "react";
import cx from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../../components/Snackbar/Snackbar";

const styles = {
  clickContainer: {
    cursor: "pointer"
  }
};

const defaultValue = {};

const { Provider, Consumer } = React.createContext(defaultValue);

const initialState = {
  message: "",
  color: "info",
  close: true,
  icon: undefined,
  place: "bl",
  open: false
};

class SnackbarContainer extends Component {
  startSnackbar = ({ autoHideDuration = 3000, ...params }) => {
    this.setState({ ...initialState, ...params, open: true }, () =>
      setTimeout(this.closeSnackbar, autoHideDuration)
    );
  };

  closeSnackbar = () =>
    this.setState(
      { open: false },
      () => this.state.onClose && this.state.onClose()
    );

  clickSnackbar = () => this.state.onClick && this.state.onClick();

  state = {
    ...initialState,
    startSnackbar: this.startSnackbar,
    closeSnackbar: this.closeSnackbar
  };

  render() {
    const { classes, children } = this.props;
    return (
      <Provider value={this.state}>
        <div
          className={cx({ [classes.clickContainer]: !!this.state.onClick })}
          onClick={this.clickSnackbar}
        >
          <Snackbar {...this.state} closeNotification={this.closeSnackbar} />
        </div>
        {children}
      </Provider>
    );
  }
}

SnackbarContainer = withStyles(styles)(SnackbarContainer);

export {
  SnackbarContainer,
  Provider as SnackbarProvider,
  Consumer as SnackbarConsumer
};
