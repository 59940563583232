import db, { firebase } from "./firebase";

class EmployeeService {
  static get collectionName() {
    return "employees";
  }
  static doc() {
    return db.collection(this.collectionName).doc();
  }
  static add(employeeObject) {
    return db.collection(this.collectionName).add(employeeObject);
  }

  static async data(employeeReference) {
    return {
      ...(await employeeReference.get()).data(),
      id: employeeReference.id,
      selfReference: employeeReference.path
    };
  }

  static set(employeeObject) {
    const id = employeeObject.id
      ? employeeObject.id
      : db.collection(this.collectionName).doc().id;

    return db
      .collection(this.collectionName)
      .doc(id)
      .set({
        ...employeeObject,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
  }
  static async findById(employeeId, asData = false) {
    let employee = await db
      .collection(this.collectionName)
      .doc(employeeId)
      .get();

    if (!employee.exists) {
      employee = await db
        .collection("selfManagedEmployees")
        .doc(employeeId)
        .get();
    }

    return employee && employee.exists
      ? asData
        ? { ...employee.data(), id: employee.id }
        : employee
      : null;
  }
  static updateWithObject(values) {
    return db
      .collection("employees")
      .doc(values.id)
      .update({
        ...values,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
  }

  static query(query, callback, callbackError = null, parser) {
    return query
      .get()
      .then(async result => {
        const documents = result.docs.map(item => {
          return parser
            ? parser({
                ...item.data(),
                id: item.id,
                selfReference: item.ref.path
              })
            : { ...item.data(), id: item.id, selfReference: item.ref.path };
        });
        const resolvedDocuments = await Promise.all(documents);
        return callback ? callback(resolvedDocuments) : resolvedDocuments;
      })
      .catch(error => {
        return callbackError ? callbackError(error) : null;
      });
  }
}

export default EmployeeService;
