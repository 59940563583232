import {
  LOGOUT_USER,
  SAVE_USER_DATA,
  SAVE_EMPLOYEE_DATA,
  SAVE_COMPANY_REFERENCE,
  SAVE_EMPLOYEES_LIST_COMPANY,
  SAVE_COMPANY_DATA,
  SAVE_SUBSCRIPTION,
  CHANGE_EMPLOYEE_SESSION,
  SHOW_TIPS,
  HIDE_TIPS,
  SAVE_SERVER_TIME
} from "../actions";

const initialState = {
  employeeData: null,
  userData: null,
  companyReference: null,
  employeesOfCompany: [],
  employeesReferenceOfCompany: [],
  companyData: null,
  subscription: null,
  showTips: {
    dashboard: false,
    clockwork: false,
    requests: false,
    employees: false
  },
  serverTime: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_DATA:
      return {
        ...state,
        userData: action.payload
      };
    case SAVE_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: action.payload
      };
    case SAVE_COMPANY_REFERENCE:
      return {
        ...state,
        companyReference: action.payload
      };
    case SAVE_EMPLOYEES_LIST_COMPANY:
      return {
        ...state,
        employeesReferenceOfCompany: action.payload
      };
    case LOGOUT_USER:
      return {
        ...state,
        employeeData: null,
        currentUser: null,
        userData: null,
        companyReference: null,
        employeesOfCompany: [],
        employeesReferenceOfCompany: [],
        companyData: null,
        subscription: null
      };
    case CHANGE_EMPLOYEE_SESSION:
      return {
        ...state,
        employeeData: null,
        companyReference: null,
        employeesOfCompany: [],
        employeesReferenceOfCompany: [],
        companyData: null,
        subscription: null
      };
    case SAVE_COMPANY_DATA:
      return {
        ...state,
        companyData: action.payload
      };
    case SAVE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload
      };
    case SHOW_TIPS:
      return {
        ...state,
        showTips: {
          dashboard: true,
          clockwork: true,
          requests: true,
          employees: true
        }
      };
    case HIDE_TIPS:
      return {
        ...state,
        showTips: {
          ...state.showTips,
          [action.payload]: false
        }
      };
    case SAVE_SERVER_TIME:
      return {
        ...state,
        serverTime: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;
