export const DEV = false

export const CONFIG_ADMIN_PRODUCTION = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const CONFIG_ADMIN_EMULATOR = {
  projectId: process.env.REACT_APP_PROJECT_ID_EMULATOR, // when running emulator firestore the projectID has to be the same of emulator project, otherwise the data will not show.
  apiKey: process.env.REACT_APP_API_KEY,
  // :::configure storage emulator:::
  appId: process.env.REACT_APP_ID,
};

export const ORIGIN_PARTNER_ALLOWED = process.env.REACT_APP_ORIGIN_PARTNER_ALLOWED
export const PONTOGO_API_KEY = process.env.REACT_APP_ORIGIN_PARTNER_ALLOWED