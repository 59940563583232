import {
  NEED_NOTIFICATION_PERMISSION,
  SHOW_NOTIFICATION,
  CLEAR_NOTIFICATION,
  SET_NOTIFICATION_BADGE,
  CLEAR_NOTIFICATION_BADGES
} from "../actions/messaging";

const initialState = {
  needNotificationPermission: false,
  notification: null,
  badges: {
    Solicitações: 0,
    allowance: 0,
    pointAdjustment: 0
  }
};

const messagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEED_NOTIFICATION_PERMISSION:
      return {
        ...state,
        needNotificationPermission: action.payload
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: null
      };
    case SET_NOTIFICATION_BADGE:
      const type = action.payload.type;
      let badges = {
        ...state.badges,
        [type]: action.payload.amount
      };
      if (type === "allowance" || type === "pointAdjustment") {
        badges = {
          ...badges,
          Solicitações: badges.allowance + badges.pointAdjustment
        };
      }

      return {
        ...state,
        badges: badges
      };
    case CLEAR_NOTIFICATION_BADGES:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default messagingReducer;
