import { messaging } from "../../services/firebase";
import { getDocument } from "../../services/FirestoreService";
import TeamsService from "../../services/TeamsService";
import { ADMIN, MANAGER, PENDING } from "../../model/constants";
import Teams from "../../model/Teams";

export const NEED_NOTIFICATION_PERMISSION = "NEED_NOTIFICATION_PERMISSION";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const SET_NOTIFICATION_BADGE = "SET_NOTIFICATION_BADGE";
export const CLEAR_NOTIFICATION_BADGES = "CLEAR_NOTIFICATION_BADGES";

let unsubscribeAllowances = null;
let unsubscribePointAdjustment = null;

export const startMessaging = (
  user,
  role,
  employee,
  company
) => async dispatch => {
  if (messaging) {
    dispatch(getToken(user));

    messaging.onTokenRefresh(function() {
      messaging
        .getToken()
        .then(function(refreshedToken) {
          setTokenSentToServer(false);
          sendTokenToServer(refreshedToken, user);
        })
        .catch(function(err) {
          console.log("Unable to retrieve refreshed token ", err);
        });
    });

    messaging.onMessage(function(payload) {
      dispatch(showNotification(payload.data));
    });
  }

  let queryRequestAllowances = company
    .collection("requestAllowance")
    .where("status", "==", PENDING);

  let queryRequestPointAdjusments = company
    .collection("requestPointAdjustment")
    .where("status", "==", PENDING);

  if (role === ADMIN) {
    unsubscribeAllowances = queryRequestAllowances.onSnapshot(querySnapshot => {
      dispatch(
        setNotificationBadge({
          type: "allowance",
          amount: querySnapshot.size
        })
      );
    });

    unsubscribePointAdjustment = queryRequestPointAdjusments.onSnapshot(
      querySnapshot => {
        dispatch(
          setNotificationBadge({
            type: "pointAdjustment",
            amount: querySnapshot.size
          })
        );
      }
    );
  } else if (role === MANAGER) {
    const employeeRef = getDocument(employee.selfReference);

    const members = await TeamsService.getMembersOfTeams(
      employee,
      company,
      false,
      Teams.parseToReferences
    );

    const employeesRef = [...members, employeeRef];

    if (employeesRef.length <= 10) {
      queryRequestAllowances = queryRequestAllowances.where(
        "employee",
        "in",
        employeesRef
      );
      queryRequestPointAdjusments = queryRequestPointAdjusments.where(
        "employee",
        "in",
        employeesRef
      );
    }

    unsubscribeAllowances = queryRequestAllowances.onSnapshot(querySnapshot => {
      const results = querySnapshot.docs.filter(doc => {
        const allowance = doc.data();
        return !!employeesRef.find(
          employee => employee.id === allowance.employee.id
        );
      });
      dispatch(
        setNotificationBadge({
          type: "allowance",
          amount: results.length
        })
      );
    });

    unsubscribePointAdjustment = queryRequestPointAdjusments.onSnapshot(
      querySnapshot => {
        const results = querySnapshot.docs.filter(doc => {
          const pointAdjustment = doc.data();
          return !!employeesRef.find(
            employee => employee.id === pointAdjustment.employee.id
          );
        });
        dispatch(
          setNotificationBadge({
            type: "pointAdjustment",
            amount: results.length
          })
        );
      }
    );
  }
};

const getToken = user => dispatch => {
  if (messaging) {
    messaging
      .getToken()
      .then(function(currentToken) {
        if (currentToken) {
          sendTokenToServer(currentToken, user);
        } else {
          setTokenSentToServer(false);
          dispatch(needNotificationPermission(true));
        }
      })
      .catch(function(err) {
        console.log("An error occurred while retrieving token. ", err);
        setTokenSentToServer(false);
      });
  }
};

async function sendTokenToServer(currentToken, user) {
  if (!isTokenSentToServer()) {
    const userData = (await user.get()).data();
    const tokenIndex = userData.notificationTokens
      ? userData.notificationTokens.indexOf(currentToken)
      : -1;
    if (tokenIndex === -1) {
      const notificationTokens = userData.notificationTokens
        ? [...userData.notificationTokens, currentToken]
        : [currentToken];
      user
        .update({ notificationTokens })
        .then(() => setTokenSentToServer(true))
        .catch(() => setTokenSentToServer(false));
    } else {
      setTokenSentToServer(true);
    }
  } else {
    console.log(
      "Token already sent to server so won't send it again " +
        "unless it changes"
    );
  }
}

function isTokenSentToServer() {
  return window.sessionStorage.getItem("sentToServer") === "1";
}

function setTokenSentToServer(sent) {
  window.sessionStorage.setItem("sentToServer", sent ? "1" : "0");
}

export const requestPermission = user => dispatch => {
  if (messaging) {
    messaging
      .requestPermission()
      .then(function() {
        dispatch(needNotificationPermission(false));
        dispatch(getToken(user));
      })
      .catch(function(err) {
        console.log("Unable to get permission to notify.", err);
      });
  }
};

export const deleteToken = user => dispatch => {
  dispatch(clearMessaging());
  if (messaging) {
    return messaging
      .getToken()
      .then(function(currentToken) {
        return (
          currentToken &&
          messaging
            .deleteToken(currentToken)
            .catch(function(err) {
              console.log("Unable to delete token. ", err);
            })
            .then(async function() {
              const userData = (await user.get()).data();
              const notificationTokens = userData.notificationTokens
                ? userData.notificationTokens.filter(
                    token => token !== currentToken
                  )
                : [];
              setTokenSentToServer(false);
              return user.update({ notificationTokens });
            })
        );
      })
      .catch(function(err) {
        console.log("Error retrieving Instance ID token. ", err);
      });
  }

  return Promise.resolve();
};

export const clearMessaging = () => dispatch => {
  unsubscribeAllowances && unsubscribeAllowances();
  unsubscribePointAdjustment && unsubscribePointAdjustment();
  dispatch(clearNotificationBadges());
};

export const needNotificationPermission = value => ({
  type: NEED_NOTIFICATION_PERMISSION,
  payload: value
});

export const showNotification = data => ({
  type: SHOW_NOTIFICATION,
  payload: data
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION
});

export const setNotificationBadge = badge => ({
  type: SET_NOTIFICATION_BADGE,
  payload: badge
});

export const clearNotificationBadges = () => ({
  type: CLEAR_NOTIFICATION_BADGES
});
