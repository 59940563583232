class Teams {
  static parseToReferences(employee) {
    return employee.ref;
  }

  static create(team) {
    return {
      active: true,
      members: [],
      managers: [],
      countMembers: null,
      ...team
    };
  }

  static ParseToList(team) {
    return {
      ...team,
      members: team.members,
      managers: team.managers,
      countMembers:
        ((team.members && team.members.length) || 0) +
        ((team.managers && team.managers.length) || 0)
    };
  }
}

export default Teams;
