import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import registerServiceWorker from "./registerServiceWorker";
import { checkAuthStatus } from "./store/actions/index";
import Root from "./Root";
import moment from "moment-timezone";
import localeErrors from "./model/Forms/localeErrors.js";
import "assets/scss/material-dashboard-pro-react.css?v=1.2.0";
import "moment/locale/pt-br";
import { SnackbarContainer } from "./layouts/SnackbarContext/SnackbarContext";
import NewContent from "./components/NewContent/NewContent";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Theme from "./layouts/Theme";
import "./override.css"

if (process.env.NODE_ENV == "production") {
  if (window.Raven) {
    window.Raven.config(
      "https://9d7d280b8a77426a9dcf05a2d22eac98@sentry.io/1258075",
      {
        release: process.env.COMMITHASH.trim()
      }
    ).install();
  }
}

moment.tz.setDefault("America/Brasilia");
// store.dispatch(checkAuthStatus());

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={Theme}>
      <SnackbarContainer>
        <Root />
        <NewContent />
      </SnackbarContainer>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
