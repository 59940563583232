import React, {useEffect} from "react";

import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";

const ProtectedRoute = ({
  component: Component,
  role,
  employee,
  company,
  authorized,
  userData,
  companyData,
  subscription,
  employeesReferenceOfCompany,
  CPFModeUser,
  ...rest
}) => {

  return (
    <Route
      {...rest}
      render={props => {
        const params = queryString.parse(props.location.search);

        // Veio pelo convite
        if (params.email && params.employeeId && params.company && userData) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard/associar-contas",
                state: params
              }}
            />
          );
        }

        // Tentando fazer algo que não está autorizado
        if (!employee && !CPFModeUser && props.match.path !== "/inicio") {
          return (
            <Redirect
              to={{
                pathname: "/inicio"
              }}
            />
          );
        } else if (CPFModeUser && props.match.path !== "/smart-ponto") {
          return (
            <Redirect
              to={{
                pathname: "/smart-ponto"
              }}
            />
          );
        } else if (
          employee &&
          company &&
          !authorized &&
          props.match.path !== "/dashboard"
        ) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard"
              }}
            />
          );
        }


        return <Component {...props} />;
      }}
    />
  );
};
function mapStateToProps(state) {
  return {
    employee: state.userReducer.employeeData,
    company: state.userReducer.companyReference,
    userData: state.userReducer.userData,
    companyData: state.userReducer.companyData,
    subscription: state.userReducer.subscription,
    employeesReferenceOfCompany: state.userReducer.employeesReferenceOfCompany,
    CPFModeUser:
      state.CPFModeReducer.companiesList || state.CPFModeReducer.companyData
  };
}
export default connect(mapStateToProps, null)(ProtectedRoute);
