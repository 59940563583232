import { setLocale } from "yup";

setLocale({
  mixed: {
    default: "Não é válido",
    required: "Campo obrigatório",
    notType: "Valor inválido",
    min: "Deve ser maior que ${min}"
  },
  string: {
    min: "Mínimo de ${min} caracteres",
    email: "Formato inválido de email",
    matches: "Formato inválido"
  },
  number: {
    min: "Deve ser maior que ${min}",
    notType: "Valor inválido"
  }
});
