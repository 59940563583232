import { AbilityBuilder, Ability } from "@casl/ability";
import { createCanBoundTo } from "@casl/react";
import store from "../../store";

Ability.addAlias("list", ["read"]);
Ability.addAlias("control", ["list", "manage"]);
Ability.addAlias("createBasedOnPlan");

function getAllPermissionsOfRole(role) {
  const admin = AbilityBuilder.define(can => {
    can("control", "all");
    can("list", "all");
  });

  const manager = AbilityBuilder.define(can => {
    can("read", "Event");
    can("list", "Employee");
    can("list", "CompTime");
    can("control", "Point");
    can("control", "Allowance");
    can("control", "RequestAllowance");
    can("control", "RequestPointAdjustment");
    can("control", "Report");
    can("control", "PointMethods");
    can("control", "Closures");
  });

  const reports = AbilityBuilder.define(can => {
    can("list", "Employee");
    can("list", "Point");
    can("control", "Report");
  });

  const employee = AbilityBuilder.define(can => {
    can("read", "Employee");
    can("list", "Point");
    can("create", "Point");
    can("list", "RequestAllowance");
    can("list", "RequestPointAdjustment");
    can("create", "RequestAllowance");
    can("create", "RequestPointAdjustment");
  });

  const nocompany = AbilityBuilder.define(can => {
    can("read", "Employee");
    can("list", "Point");
    can("create", "Point");
  });

  const ABILITIES = { admin, manager, reports, employee, nocompany };

  return ABILITIES[role];
}

export function defineAbilityFor(user) {
  const { rules, can, cannot } = AbilityBuilder.extract();
  if (user.permissions && user.permissions.includes("all")) {
    return getAllPermissionsOfRole(user.role);
  } else {
    user.permissions &&
      user.permissions.map(permission => {
        const permissionProperty = permission.split("_");
        can(permissionProperty[0], permissionProperty[1]);
      });
  }
  return new Ability(rules);
}

export function defineAbilityForPlan(activeEmployeeCounter, maxEmployees) {
  const { rules, can, cannot } = AbilityBuilder.extract();

  if (activeEmployeeCounter >= maxEmployees) {
    cannot("createBasedOnPlan", "Employee");
  } else {
    can("createBasedOnPlan", "Employee");
  }

  return new Ability(rules);
}

const ability = defineAbilityFor({ permissions: [], role: null });

export const Can = createCanBoundTo(ability);

export default ability;

/*

control = read | update | delete | create | list

employee: 0
admin: 1
nocompany: 2
anonymous: 3
manager: 4
reports: 5


control EmployeeShift
control Point
control Event
control Employee
control Shift
control Holiday
control Jobtitle
read RequestAllowance
update RequestPointAdjustment
control Points

TELA DE SHIFT = list shift
botões da tela = read/update/delete/create/list shift



control FUNCIONALIDADE
read employeePoints
read solicitations
control solicitations

listar employees
listar eventos



*/
