export const APPROVED = 1;
export const DISAPPROVED = 2;
export const PENDING = 0;

export const ON_WORKING = 0;
export const FIRED = 1;
export const INACTIVE = 2;
export const READ_ONLY = 3;

export const CHANGE = 0;
export const DELETE = 1;
export const ADD = 2;
export const UNCHANGE = 3;
export const DISREGARD = 4;
export const RECONSIDERED = 5;
export const CHANGE_TYPES = ["D", "D", "I", "P"];

export const EMPLOYEE = 0;
export const ADMIN = 1;
export const NO_COMPANY = 2;
export const ANNONYMOUS = 3;
export const MANAGER = 4;
export const REPORTS = 5;

export const FORA_JORNADA = 0;
export const EM_JORNADA = 1;

export const SHIFT_DEFAULT = { name: "Turno padrão 8hr", type: 0 };
export const JOBTITLE_DEFAULT = "Cargo padrão";
export const JOURNEY_RULE_DEFAULT = "Regra da jornada padrão";

export const UNPAID = 0;
export const PAID = 1;
export const ENDED = 2;
export const CANCELED = 3;
export const PENDING_PAYMENT = 4;
export const TRIALING = 5;

export const TRIAL_DAYS = 7;
export const TRIAL_MAX_EMPLOYEES = 100;

export const GOOGLE_MAPS_KEY = "AIzaSyAMRcbq8vWYHbk_BUsUBKMrG1dnvyfNm14";

export const TEST_ENCRYPTION_KEY_DEPRECATED =
  "ek_test_2pB9TGdkyBVUaAeoLYtW8Q8Kv6mPVH";
export const LIVE_ENCRYPTION_KEY_DEPRECATED =
  "ek_live_YNHKhjJfPj6bKiHBONq7IGkiIvZBok";

export const LIVE_ENCRYPTION_KEY = "ek_live_JHbedzlefUD7pHpiVvgosxjM5Z7Hxj";

export const STARTED = 0;
export const FINISHED = 1;

export const PARTIAL_RESET = 0;
export const FULL_RESET = 1;
export const RESET_BUSINESSUNITS = 2;

export const REGULAR_HOUR = 0;
export const REDUCED_HOUR = 1;

export const CREDIT_CARD = 0;
export const BOLETO = 1;

export const COMP_TIME_EXPIRATION = 2;
