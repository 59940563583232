import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import EmployeeService from "../../services/EmployeeService";

import TeamsService from "../../services/TeamsService";
import { packRules, unpackRules } from "@casl/ability/extra";
import {
  ADMIN,
  ON_WORKING,
  UNPAID,
  ENDED,
  CANCELED,
  TRIALING,
  PENDING_PAYMENT,
  PAID,
  MANAGER
} from "../../model/constants";
import db, { firebase } from "../../services/firebase";
import ability, {
  defineAbilityFor,
  defineAbilityForPlan
} from "../../container/RoutesACL/Admin";
import { getNameOfRole } from "../../utils/auth";
import {
  normalizeEmployee,
  normalizeUser,
  normalizeCompany
} from "../../utils/normalizeData";
import { startMessaging, deleteToken, clearMessaging } from "./messaging";
import { getDocument } from "../../services/FirestoreService";
import Subscription from "../../model/Subscription";
import { getServerTimestamp } from "../../services/generalService";
import { ORIGIN_PARTNER_ALLOWED } from "../../envconfig";
export const HANDLE_LOGIN = "HANDLE_LOGIN";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const CHANGE_EMPLOYEE_SESSION = "CHANGE_EMPLOYEE_SESSION";
export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const SAVE_EMPLOYEE_DATA = "SAVE_EMPLOYEE_DATA";
export const SAVE_EMPLOYEES_LIST_COMPANY = "SAVE_EMPLOYEES_LIST_COMPANY";
export const SAVE_COMPANY_REFERENCE = "SAVE_COMPANY_REFERENCE";
export const SAVE_COMPANY_DATA = "SAVE_COMPANY_DATA";
export const SAVE_SUBSCRIPTION = "SAVE_SUBSCRIPTION";
export const SAVE_SERVER_TIME = "SAVE_SERVER_TIME";
export const SHOW_TIPS = "SHOW_TIPS";
export const HIDE_TIPS = "HIDE_TIPS";

let unsubscribeAuth = null;
let unsubscribeCompany = null;
let unsubscribeSubscription = null;
let unsubscribeTrialTimeout = null;

export const getCurrentSession = companyId => dispatch => {
  unsubscribeAuth = firebase.auth().onAuthStateChanged(async user => {
    if (user) {
      const userReference = await UserService.findByEmail(user.email);
      if (userReference) {
        dispatch(
          startGetUserReference(userReference.ref, undefined, companyId)
        );
        sessionStorage.setItem("currentUser", JSON.stringify(user));
      }
    }
  });
};
export const checkAuthStatus = companyId => async dispatch => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  if (user) {
    dispatch(getCurrentSession(companyId));
  }
};

export const logoutUser = userReference => dispatch => {
  return dispatch(deleteToken(userReference)).then(() => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(
          AuthService.logoutUser()
            .then(() => {
              unsubscribeAuth && unsubscribeAuth();
              unsubscribeCompany && unsubscribeCompany();
              unsubscribeSubscription && unsubscribeSubscription();
              clearTimeout(unsubscribeTrialTimeout);

              sessionStorage.removeItem("currentUser");
            })
            .then(() => dispatch({ type: LOGOUT_USER }))
        );
      }, 400);
    });
  });
};

export const startLoginUser = (login, password) => dispatch => {
  return AuthService.logginUser(login, password).then(async session => {

    const userReference = await UserService.findByEmail(session.user.email);
    if (userReference) {
      sessionStorage.setItem("currentUser", JSON.stringify(session.user));
      dispatch(startGetUserReference(userReference.ref));
    }
    return session.user;
  });
};

export const setUserPermissions = userRules => dispatch => {
  const role = getNameOfRole(userRules.role);

  const permissions = defineAbilityFor({
    permissions: userRules.permissions || userRules.permission,
    role: role
  });
  const newRules = packRules(permissions.rules);
  ability.update(unpackRules(newRules));
};

export const startChangeEmployeeReferences = (
  userReference,
  indexOfJob
) => dispatch => {
  dispatch(clearMessaging());
  dispatch(clearEmployeeReferences());
  dispatch(startGetUserReference(userReference, indexOfJob));
};

export const changeToNotificationEmployee = (
  user,
  currentCompanyId,
  notificationCompanyId
) => async dispatch => {
  if (currentCompanyId !== notificationCompanyId) {
    let userData = user;
    let userPath = userData && userData.selfReference;
    if (!userPath) {
      const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      userPath = `users/${currentUser.uid}`;
      userData = (await getDocument(userPath).get()).data();
    }

    const userReference = getDocument(userPath);
    const indexOfJob = await UserService.getJobIndexOfUserFromCompany(
      userData,
      notificationCompanyId
    );
    dispatch(startChangeEmployeeReferences(userReference, indexOfJob));
  }
};

export const startGetUserReference = (
  userReference,
  indexOfJob,
  companyId
) => async dispatch => {
  let userData = await normalizeUser({
    ...(await userReference.get()).data(),
    selfReference: userReference.path
  });


  let employeeReference = null;
  if (companyId) {
    const jobIndex = await UserService.getJobIndexOfUserFromCompany(
      userData,
      companyId
    );

    employeeReference = userData.jobs[jobIndex];

    if (
      jobIndex !== null &&
      userData.configuration.currentEmployee !== jobIndex
    ) {
      const configuration = {
        ...userData.configuration,
        currentEmployee: jobIndex
      };
      userReference.update({ configuration });
      userData = { ...userData, configuration };
    }
  } else {
    employeeReference = await UserService.getEmployeeOfUserJobs(
      userReference,
      indexOfJob !== undefined
        ? indexOfJob
        : userData.configuration.currentEmployee
    );
  }
  dispatch(saveUserData(userData));


  const employeeData = await normalizeEmployee(
    await EmployeeService.data(employeeReference),
    employeeReference.path,
    userReference
  );


  const roleData = (await employeeData.rule.get()).data();

  dispatch(setUserPermissions(roleData));
  dispatch(
    saveEmployeeData({
      ...employeeData,
      rule: employeeData.rule.path,
      company: employeeData.company.path,
      selfReference: employeeReference.path,
      role: roleData.role
    })
  );
  dispatch(saveCompanyReference(employeeData.company));

  const query = db
    .collection("employees")
    .where("company", "==", employeeData.company)
    .where("status", "==", ON_WORKING);

  if (roleData.role == ADMIN) {
    const employeesReferenceOfCompany = await EmployeeService.query(query);
    const onlyEmployeeReferences = employeesReferenceOfCompany.map(
      employee => employee.selfReference
    );
    dispatch(saveEmployeesReferenceOfCompany(onlyEmployeeReferences));
  } else if (roleData.role === MANAGER) {
    try {
      const employees = await TeamsService.getMembersOfTeams(
        employeeData,
        employeeData.company
      );

      const employeesPath = [...employees, employeeData].map(employee => {
        return employee.selfReference;
      });

      dispatch(saveEmployeesReferenceOfCompany(employeesPath));
    } catch (error) {
      dispatch(saveEmployeesReferenceOfCompany([]));
    }
  } else {
    dispatch(saveEmployeesReferenceOfCompany([]));
  }

  let timestamp = null;
  try {
    timestamp = await getServerTimestamp(false);
    dispatch(saveServerTime(timestamp));
  } catch (e) {
    console.log(e);
  }

  const companyPromise = new Promise(resolve => {
    unsubscribeCompany = employeeData.company.onSnapshot(doc => {
      normalizeCompany({
        ...doc.data(),
        selfReference: doc.ref.path
      }).then(company => {
        if (company.businessUnits) {
          const totalActiveEmployees = company.businessUnits.data.reduce(
            (accumulator, { activeEmployeeCounter }) => {
              return accumulator + activeEmployeeCounter;
            },
            company.activeEmployeeCounter
          );

          resolve({ ...company, id: doc.id, totalActiveEmployees });
          dispatch(
            saveCompanyData({ ...company, id: doc.id, totalActiveEmployees })
          );
        } else {
          resolve({
            ...company,
            id: doc.id,
            totalActiveEmployees: company.activeEmployeeCounter
          });
          dispatch(
            saveCompanyData({
              ...company,
              id: doc.id,
              totalActiveEmployees: company.activeEmployeeCounter
            })
          );
        }
      });
    });
  });

  const company = await companyPromise;

  if (company.originPartner !== ORIGIN_PARTNER_ALLOWED) {
    sessionStorage.clear()
    window.location.reload()
  }

  unsubscribeSubscription = db
    .collection("subscriptions")
    .where(
      "company",
      "==",
      company && company.mainCompany
        ? company.mainCompany
        : employeeData.company
    )
    .orderBy("startDate", "desc")
    .onSnapshot(async querySnapshot => {
      let currentSubscription = Subscription.create({
        ...Subscription.LEGACY,
        company: employeeData.company
      });

      if (!querySnapshot.empty) {
        const subscription = querySnapshot.docs[0].data();
        currentSubscription = subscription;

        const subscriptionExpired = await Subscription.isExpired(
          subscription,
          timestamp
        );
        if (
          subscriptionExpired &&
          subscription.paymentStatus !== PENDING_PAYMENT &&
          subscription.paymentStatus !== PAID
        ) {
          currentSubscription = { ...subscription, paymentStatus: ENDED };
        } else {
          dispatch(checkSubscriptionTimeout());
        }
      }

      dispatch(
        saveSubscription({
          ...currentSubscription,
          company: currentSubscription.company.path
        })
      );
    });

  dispatch(
    startMessaging(
      userReference,
      roleData.role,
      employeeData,
      employeeData.company
    )
  );

  if (process.env.NODE_ENV == "production") {
    if (window.Raven) {
      window.Raven.setUserContext({
        employeeId: employeeReference.id,
        companyId: employeeData.company.id
      });
    }
  }
};

const checkSubscriptionTimeout = () => (dispatch, getState) => {
  unsubscribeTrialTimeout = setTimeout(async () => {
    const subscription = getState().userReducer.subscription;
    const subscriptionExpired = await Subscription.isExpired(subscription);
    if (subscriptionExpired && subscription.paymentStatus !== PENDING_PAYMENT) {
      dispatch(saveSubscription({ ...subscription, paymentStatus: ENDED }));
    } else {
      dispatch(checkSubscriptionTimeout());
    }
  }, 43200000);
};

export const saveServerTime = serverTime => ({
  type: SAVE_SERVER_TIME,
  payload: serverTime
});

export const saveUserData = userData => {
  return {
    type: SAVE_USER_DATA,
    payload: userData
  };
};

export const saveEmployeeData = employeeData => {
  return {
    type: SAVE_EMPLOYEE_DATA,
    payload: employeeData
  };
};

export const saveEmployeesReferenceOfCompany = employeesReferenceOfCompany => {
  return {
    type: SAVE_EMPLOYEES_LIST_COMPANY,
    payload: employeesReferenceOfCompany
  };
};

export const saveCompanyReference = companyReference => {
  return {
    type: SAVE_COMPANY_REFERENCE,
    payload: companyReference
  };
};

export const saveCompanyData = companyData => {
  return {
    type: SAVE_COMPANY_DATA,
    payload: companyData
  };
};

export const saveSubscription = subscription => {
  return {
    type: SAVE_SUBSCRIPTION,
    payload: subscription
  };
};

export const showTips = () => ({ type: SHOW_TIPS });
export const hideTips = tip => ({ type: HIDE_TIPS, payload: tip });

export const updatePermissions = store => next => action => {
  let result = next(action);
  const nextState = store.getState();
  const shouldUpdate =
    nextState.userReducer.companyData &&
    nextState.userReducer.subscription &&
    (action.type === SAVE_COMPANY_DATA || action.type === SAVE_SUBSCRIPTION);

  if (shouldUpdate) {
    const activeEmployeeCounter =
      nextState.userReducer.companyData.totalActiveEmployees;
    // const maxEmployees = 60;
    const maxEmployees = nextState.userReducer.subscription.plan.maxEmployees;

    const permissions = defineAbilityForPlan(
      activeEmployeeCounter,
      maxEmployees
    );
    const oldRules = packRules(ability.rules);
    const newRules = packRules(permissions.rules);
    const rules = oldRules.concat(newRules);
    ability.update(unpackRules(rules));
  }
  return result;
};

export const clearEmployeeReferences = () => {
  unsubscribeCompany && unsubscribeCompany();
  unsubscribeSubscription && unsubscribeSubscription();
  clearTimeout(unsubscribeTrialTimeout);
  return {
    type: CHANGE_EMPLOYEE_SESSION
  };
};
// export const saveCurrentUser = () =>  dispatch => {
//   firebaseAuth.onAuthStateChanged(user => {
//     dispatch(updateUserState(user))
//     sessionStorage.setItem('userLogged', JSON.stringify(user))
//   });
// }

// export const loadUserRefs = (user) => async dispatch => {

//   dispatch(updateUserState(user));

//   const userReference = await getUserReference(user.email)
//   dispatch({type: LOAD_USER_REFERENCE, payload: userReference})

//   const jobRef = await getJobReference(userReference)
//   dispatch({type: LOAD_EMPLOYEE_REFERENCE, payload: jobRef});

//   const [employeeData, currentCompany] = await Promise.all([
//     getEmployeeData(jobRef),
//     getCompanyReference(jobRef)
//   ])
//   dispatch({type: LOAD_EMPLOYEE_DATA, payload: employeeData});
//   dispatch({type: LOAD_COMPANY_REFERENCE, payload: employeeData.company});

//   if(employeeData.role !== 0){
//     const employeesOfCompany = await getUsersOfCompany(currentCompany)
//     dispatch({type: LOAD_ALL_EMPLOYEES_OF_COMPANY, payload: employeesOfCompany.docs})
//   }
// }

// export const checkUserStatus = () =>  dispatch => {
//   const user = JSON.parse(sessionStorage.getItem('userLogged'))
//   user ? dispatch(loadUserRefs(user)) : dispatch(saveCurrentUser())
// }
