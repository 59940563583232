import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/remote-config";
import "firebase/analytics";
// import "firebase/messaging";
import {CONFIG_ADMIN_EMULATOR, CONFIG_ADMIN_PRODUCTION, DEV} from "../envconfig";
// import momentDurationFormatSetup from 'moment-duration-format';
// momentDurationFormatSetup(moment);

const config = DEV ? CONFIG_ADMIN_EMULATOR : CONFIG_ADMIN_PRODUCTION;

firebase.initializeApp(config);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();
const analytics = firebase.analytics();

if(DEV){
  db.useEmulator("localhost", 8080)
  db.settings({host: 'localhost:8080', ssl: false})

  auth.useEmulator("http://localhost:9099")
  functions.useEmulator("localhost", 5001);
}

const resendBoleto = functions.httpsCallable("resendBoleto");
const deleteCompany = functions.httpsCallable("deleteCompany");
const requestCRM = functions.httpsCallable("requestCRM");
const eventCRM = functions.httpsCallable("eventCRM");
const createClosure = functions.httpsCallable("closeOscillations");
const disregardClosure = functions.httpsCallable("disregardClosure");

let messaging = null;
if ("Notification" in window && navigator.serviceWorker) {
  import("firebase/messaging")
    .then(() => {
      messaging = firebase.messaging();
      messaging.usePublicVapidKey(
        "BNHVOz7VnJu0qGRI9Mmact-UsPL0WSRR95VAHjuPQag4jV9hyGOJsLM9BJs3QeLAMbeNn1jAOygS2ArXfuU-i5o"
      );
    })
    .catch(error => error);
}

export {
  firebase,
  auth,
  storage,
  messaging,
  analytics,
  resendBoleto,
  deleteCompany,
  requestCRM,
  eventCRM,
  createClosure,
  disregardClosure
};
export default db;
