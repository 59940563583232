import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import { getDocument } from "../../services/FirestoreService";
import { ADMIN, MANAGER } from "../../model/constants";

export const CPF_MODE_SAVE_COMPANIES_LIST = "CPF_MODE_SAVE_COMPANIES_LIST";
export const CPF_MODE_SAVE_COMPANY = "CPF_MODE_SAVE_COMPANY";
export const CPF_MODE_LOGOUT_USER = "CPF_MODE_LOGOUT_USER";

export const CPFModeLogin = (login, password) => dispatch => {
  return AuthService.logginUser(login, password).then(async session => {
    const userReference = await UserService.findByEmail(session.user.email);

    if (userReference) {
      const userData = userReference.data();

      const jobsPromises = userData.jobs.map(job => {
        return job.get();
      });
      const jobsDocs = await Promise.all(jobsPromises);

      const companiesPromises = jobsDocs.map(async jobDoc => {
        const employee = jobDoc.data();

        const rule = (await getDocument(
          `${employee.company.path}/rules/${userReference.id}`
        ).get()).data();

        if (rule.role === ADMIN || rule.role === MANAGER) {
          const companyData = (await employee.company.get()).data();
          return {
            name: companyData.name,
            cnpj: companyData.cnpj,
            selfReference: employee.company.path,
            id: employee.company.id
          };
        }
      });
      const companies = (await Promise.all(companiesPromises)).filter(
        company => !!company
      );

      if (companies.length === 0) {
        await dispatch(logoutUser());
        throw { code: "auth/insufficient-permissions" };
      } else if (companies.length === 1) {
        dispatch(saveCompanyData(companies[0]));
      } else {
        dispatch(saveCompaniesList(companies));
      }
    }

    return session.user;
  });
};

export const saveCompaniesList = companies => ({
  type: CPF_MODE_SAVE_COMPANIES_LIST,
  payload: companies
});

export const saveCompanyData = companyData => ({
  type: CPF_MODE_SAVE_COMPANY,
  payload: companyData
});

export const logoutUser = () => dispatch => {
  return AuthService.logoutUser().then(() =>
    dispatch({ type: CPF_MODE_LOGOUT_USER })
  );
};
